



































import { defineComponent, computed } from '@nuxtjs/composition-api';
import type { LineItem } from '@vsf-enterprise/commercetools-types';
import type { PropType } from '@nuxtjs/composition-api';
import {
  cartGetters,
} from '@vsf-enterprise/commercetools';
import {
  useCartExtended,
  useExtraGuarantee,
} from '~/composables';
import ImgView from '~/components/atoms/ImgView/ImgView.vue';

export default defineComponent({
  name: 'AddExtraGuarantee',
  components: { ImgView },
  props: {
    product: {
      type: Object as PropType<LineItem>,
      required: true,
    },
  },
  setup (props) {
    const {
      addExtraGuaranteeProduct,
    } = useCartExtended();

    const { shouldAddExtraGuarantee } = useExtraGuarantee();

    const shouldDisplayComponent = computed(() => {
      return shouldAddExtraGuarantee(props.product);
    });

    const title = computed(() => cartGetters.getItemName(props.product));

    return {
      addExtraGuaranteeProduct,
      title,
      shouldDisplayComponent,
    };
  },
});

